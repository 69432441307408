// modal
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500;
}

// divider
.divider1 {
  width: 5px;
  height: 25px;
  border-radius: 20;
  background: #c4d7e5;
}

// divider
.divider2 {
  width: 5px;
  height: 25px;
  border-radius: 20;
  background: #05141f;
}

// divider
.divider3 {
  width: 1px;
  height: 25px;
  border-radius: 20;
  background: #9ebcd3;
}

// divider
.divider4 {
  height: 0.5px;
  border-radius: 20;
  background: #00000034;
}

// card
.card1 {
  border: 1px solid #87a8c1;
  padding: 10px !important;
  border-radius: 10px !important;
}

.card2 {
  border: 1px solid #c9e2fb;
  padding: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 4px 0px #dde6ee !important;
  min-height: 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .card2Inner {
    padding: 10px 0px;
    align-items: center;
    .card2Content {
      font-size: 13px;
      font-weight: 600 !important;
      line-height: 17px;
      color: #5b6770;
    }
  }
}

// card
.card3 {
  border: 1px solid #5b7d9a;
  padding: 5px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.position-relative {
  position: relative !important;
}
// link
.link {
  color: #05141f;
}

// link
.linkLight {
  color: #fff;
  text-decoration: none;
}

// button
.btn1 {
  background-color: #627685 !important;
  color: #fff !important;
}
.text-lightgray {
  color: rgb(179, 179, 179) !important;
}
.text-black {
  color: #000 !important;
}
.text-blue {
  color: #157dc9 !important;
}
// button
.btn2 {
  background-color: #e5e8eb !important;
  color: #333f4a !important;
  border-radius: 5px !important;
  padding: 0 !important;
}

.link-btn-underline {
  color: #a7b1bb !important;
  text-decoration: underline !important;
  font-size: 19px;
  font-weight: 600;
}

// accordion
.accordion1 {
  border: 1px solid rgb(197, 227, 250);
  border-radius: 10px !important;
  color: #000 !important;
}

// no data
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & img {
    width: 60%;
  }
}

// datagrid
.MuiDataGrid-columnHeader {
  background-color: #05141f !important;
  color: #fff;
}

.MuiDataGrid-columnHeaderCheckbox {
  .MuiSvgIcon-root {
    color: #fff !important;
  }
}

.MuiDataGrid-columnsContainer,
.MuiDataGrid-cell {
  border: 0.5px solid #f0f3f7 !important;
}

.MuiDataGrid-sortIcon,
.MuiDataGrid-menuIconButton {
  color: #fff;
}

.pageTitle {
  font-size: 17px !important;
  font-family: "KiaSignatureRegular" !important;
  line-height: 21px !important;
  color: #05141f;
}

.no-legend {
  legend {
    display: none !important;
  }
}
// Dhruv css
.gap-5 {
  gap: 5px !important;
}
.gap-10 {
  gap: 10px !important;
}
.gap-15 {
  gap: 15px !important;
}
.gap-20 {
  gap: 20px !important;
}
.gap-30 {
  gap: 30px !important;
}
.p-16 {
  padding: 16px !important;
}
.pt-16 {
  padding-top: 16px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.p-0 {
  padding: 0px !important;
}
.color-white {
  color: white !important;
}
.bg-white {
  background: #ffffff !important;
}
.bg-blue {
  background: #0a5fb4 !important;
}
.bg-light-blue {
  background: #ebf6ff !important;
}
.bg-light-green {
  background: #e1fff7 !important;
}
.bg-light-red {
  background: #fbc6bc !important;
}
.fs-5 {
  font-size: 5px !important;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mb-3 {
  margin-bottom: 3px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.p-relative {
  position: relative !important;
}
.d-flex {
  display: flex !important;
}
.text-center {
  text-align: center !important;
}
.text-end {
  text-align: end !important;
}
.text-start {
  text-align: start !important;
}
.justify-center {
  justify-content: center !important;
}
.m-auto {
  margin: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.f-grow {
  flex-grow: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
  display: flex !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-end {
  justify-content: end !important;
}
.h-100 {
  height: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-100 {
  width: 100% !important;
}
.border-radious-14 {
  border-radius: 14px !important;
}
.lightredcard {
  min-height: 66px;
  border: 1px solid #fbc6bc;
  border-radius: 4px;
  box-shadow: 0px 3px 0px 0px #fbc6bc99;
  width: 100%;
  max-width: 240px;
}
.lightredcard.active {
  border: 1px solid #0a5fb4 !important;
  box-shadow: 0px 3px 0px 0px #0a5fb4 !important;
}
.greencard {
  // padding: 16px 11px;
  min-height: 66px;
  border: 1px solid #84e3b5;
  border-radius: 4px;
  box-shadow: 0px 3px 0px 0px #60c1a480;
  width: 100%;
  max-width: 240px;
}
.greencard.active {
  border: 1px solid #0a5fb4 !important;
  box-shadow: 0px 3px 0px 0px #0a5fb4 !important;
}

.greencard .content {
  width: 65%;
  border-radius: 4px 0px 0px 4px;
}
.lightredcard .content {
  width: 65%;
  border-radius: 4px 0px 0px 4px;
}
.greencard .side-content {
  width: 35%;
  padding: 16px 7px;
  border-radius: 0px 4px 4px 0px;
}
.lightredcard .side-content {
  width: 35%;
  padding: 16px 7px;
  border-radius: 0px 4px 4px 0px;
}
.greencard.active {
  .content {
    color: #ffffff !important;
    background: #0a5fb4 !important;
  }
  svg path {
    fill: #ffffff;
    color: #ffffff;
    fill-opacity: 1 !important;
  }
}

.lightredcard.active {
  .content {
    color: #ffffff !important;
    background: #0a5fb4 !important;
  }
  svg path {
    fill: #ffffff;
    color: #ffffff;
    fill-opacity: 1 !important;
  }
}
.rightseperator {
  margin-right: 20px;
}
.rightseperator::after {
  content: "";
  width: 11.5px;
  height: 100%;
  background: url(../assets/svg/rightseperator.svg);
  position: absolute;
  right: -19px;
  top: 0px;
  background-repeat: no-repeat;
  background-size: contain;
}

.select-btn {
  background: #ebf6ff !important;
  color: #05141f !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  text-align: center !important;
  height: 55px;
  padding: 9.5px 20.8px !important;
  border-radius: 4px !important;
  min-width: auto !important;
}
.black-btn {
  background: #05141f !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-align: center !important;
  padding: 16px 30px !important;
  border-radius: 7px !important;
}
.Cancel-btn {
  background: #bed2e1 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-align: center !important;
  padding: 16px 30px !important;
  border-radius: 7px !important;
}
.white-btn {
  background: #ffffff !important;
  color: #05141f !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-align: center !important;
  padding: 16px 30px !important;
  border-radius: 7px !important;
  // border: 1px solid #0d0d0d !important;
  border: 1px solid #B7C6D5 !important;
}
.select-btn.active {
  background: #0a5fb4 !important;
  color: #ffffff !important;
}
.flex-column {
  flex-direction: column !important;
}

.upload-box {
  display: flex;
  background: #e2f1ff;
  border-radius: 14px;
  max-width: 213px;
  width: 100%;
  // height: 181px;
}
.cstm-inputfield {
  border-radius: 7px !important;
  background: #ffffff;
  input {
    padding-block: 12px !important ;
  }
  fieldset {
    border: 1px solid #b2c2ce !important;
  }
}

.btn-more {
  background: #d3e1ef !important;
  border-radius: 5px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 13px !important;
  padding: 11px 30px !important;
  gap: 9px !important;
}

.link-btn {
  color: #a7b1bb !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-align: center !important;
  padding: 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #a7b1bb !important;
}

// .layout-sidecard-panel-btn{
//     max-width:108px !important;
//     width:47% !important;
//     height:75px !important;
//     border-radius:4px !important;
//     box-shadow:none !important;
//     border: 1px solid #E4F1FF !important;
//     padding:20px 4px !important;
//     cursor:pointer !important;
//     &.active{
//         background: #0A5FB4 !important;
//         color: #ffffff !important;
//         svg{
//             color: #ffffff !important;
//             path{
//                 fill: #ffffff !important;
//             }
//             circle{
//                 fill: #0A5FB4 !important;
//                 stroke: #ffffff !important;
//             }
//         }
//     }
// }
.layout-sidecard-panel-btn {
  // max-width:108px !important;
  
  width: 100% !important;
  height: 75px !important;
  // min-height: 150px;
  // background:#FBFBFB !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  border: 1px solid #e4f1ff !important;
  padding: 20px 4px !important;
  cursor: pointer !important;

  @media screen and (min-width:1500px) {
    width:43% !important;
  }

  &.active {
    background: #0a5fb4 !important;
    color: #ffffff !important;
    svg {
      color: #ffffff !important;
      path {
        fill: #ffffff !important;
      }
      circle {
        fill: #0a5fb4 !important;
        stroke: #ffffff !important;
      }
    }
  }
}

.download-btn {
  background: #05141f !important;
  border-radius: 100% !important;
  height: 31px !important;
  width: 31px !important;
  min-width: 31px !important;
}

.solidtable {
  border: 1px solid #d1e4f7;
  border-radius: 8px !important;
  thead {
    background-color: #f9fcff !important;
    th {
      background-color: #85949f !important;
      color: #ffffff !important;
    }
  }
  .MuiTableRow-root:nth-of-type(odd) {
    background-color: #f9fcff !important;
  }
  .MuiTableRow-root:nth-of-type(even) {
    background-color: #f9fcff !important;
  }
}

.MuiTableRow-root:nth-of-type(odd) {
  background-color: #ffffff !important;
}
.MuiTableRow-root:nth-of-type(even) {
  background-color: #f0f8ff !important;
}

table {
  th {
    // padding: 0px !important;
  }
  td {
    padding: 0px 16px !important;
    min-height: 43px !important;
  }
  .doneBtn {
    border: 1px solid #70c094 !important;
    background: #edfff5 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: center !important;
    color: #1c7c04 !important;
    min-width: 90px !important;
    padding: 4px 10px !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
  }
  .notdoneBtn {
    border: 1px solid #ff7d7d !important;
    background: #ffe0e0 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: center !important;
    color: #f92306 !important;
    min-width: 90px !important;
    padding: 4px 10px !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
  }
  .DownloadBtn {
    border: 1px solid #8fbde3 !important;
    background: #ebf6ff !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: center !important;
    color: #05141f !important;
    min-width: 90px !important;
    padding: 4px 10px !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
  }
}
.back-btn {
  border: 1px solid #8fbde3 !important;
  background: #ebf6ff !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: center !important;
  color: #05141f !important;
  min-width: 90px !important;
  padding: 4px 10px !important;
  border-radius: 7px !important;
  text-transform: capitalize !important;
}
.layout-blue-btn {
  background: #0a73c1 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  line-height: normal !important;
  font-weight: 400 !important;
  min-height: 35px;
  text-transform: capitalize !important;
}

.layout-lightblue-btn {
  background: #c9e2fb !important;
  color: #05141f !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  line-height: normal !important;
  font-weight: 400 !important;
  min-height: 35px;
  text-transform: capitalize !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.orange-btn {
  background: #fff4e8 !important;
  color: #e5841b !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-align: center !important;
  padding: 16px 30px !important;
  border-radius: 7px !important;
  border: 1px solid #ffcd97 !important;
}
.green-btn {
  background: #f0fff4 !important;
  color: #6cd817 !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-align: center !important;
  padding: 16px 30px !important;
  border-radius: 7px !important;
  border: 1px solid #2eda69 !important;
}
.filterField2 {
  margin-top: 6px !important;
  color: #05141f !important;
  border-radius: 5px !important;
  padding: 3px 9px !important;
  font-size: 13px !important;
  font-family: "KiaSignatureRegular" !important;
  border: 1px solid #a2b9c9 !important;
  background: transparent !important;
  .MuiInput-underline {
    background: transparent !important;
  }
  .MuiInputBase-root::before {
    display: none;
  }
  .MuiInputBase-root::after {
    display: none;
  }
}
.filterField2::before,
.filterField2::after {
  display: none !important;
}

.popup-header-rev {
  flex-direction: column-reverse;
  .MuiCardHeader-action {
    margin-left: auto;
  }
  .MuiCardHeader-content span {
    font-weight: 600 !important;
    width: 100%;
    text-align: center;
    color: #05141f;
  }
}

.uploadedfilename {
  margin-top: 10px;
  width: 190px;
  display: flex;
  border-radius: 117px;
  padding: 3px 10px;
  border: 1px solid #a9bbcc;
  background: #ffffff;
  box-sizing: border-box;
  p {
    margin-block: auto;
    width: calc(190px - 24px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal !important;
  }
  svg {
    cursor: pointer;
    width: 24px;
    margin-left: auto !important;
  }
}
.btn-3 {
}

// -------------------materialselection-------------------
.available {
  font-size: 11px;
  color: #15640f;
  padding: 5px 10px;
  background: #d5ffd4;
  border-radius: 5px;
}

// -------------------materialselection end-------------------

.order-card {
  padding: 13px 26px 20px 27px !important;
  color: #05141f !important;
  box-shadow: 0px 4px 24px 0px #e0f0ff !important;
  .MuiCardHeader-title {
    font-weight: 600 !important;
    color: #05141f !important;
    font-size: 12px !important;
  }
  .MuiCardHeader-subheader {
    font-weight: 600 !important;
    color: #157dc9 !important;
    font-size: 14px !important;
  }
}
.cstm-tablecell1 {
  font-weight: 600 !important;
  // font-size: 14px !important;
  font-size: 0.9rem !important;
  color: #05141f !important;
  padding: 15px 10px 15px 18px !important;
  font-family: "KiaSignatureRegular" !important;
}
.cstm-tablecell1.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.cstm-tablecell2.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.cstm-tablecell2 {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #05141f;
  padding: 15px 5px 15px 0px !important;
  border-right: 1px solid #d1e4f7 !important;
}
.cstm-tablecell2.fw-400 {
  font-weight: 400 !important;
}
.cstm-tablecell2::before {
  content: ":";
  padding-right: 10px !important;
}

.no-border {
  border: none !important;
}
.cursor-pointer {
  cursor: pointer !important;
}

.not-required-selected {
  background-color: black !important;
  color: white !important;
}

// .orange-btn:disabled {
//     background-color: #e4aeaefc !important;
//     color: white !important;
//     cursor: not-allowed;
//     border-color: #fff;
//   }

button.Mui-disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  pointer-events: all !important;
}

button {
  text-transform: capitalize !important;
}
button *{
  text-transform: none !important;
}
.qtySpan{
  // display: inline-block;
  border: 1px solid #5B7D9A;
  padding: 3px;
  border-radius:4px;
}
.qtySpan input{
  text-align: center;
  border: none;
  width: 45px;
}
.qtySpan fieldset{
  border:none;
}
.qtySpan button{
  width: 20px;
  min-width: unset !important;
  height: 22px;
  background-color: #E5E8EB;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qtySpan button svg{
  width: 16px;
}
.Mui-error{
  margin-inline: 0 !important;
}
.MuiDataGrid-columnSeparator{
  display: none !important;
}
button.Mui-disabled:not(.MuiPickersDay-root) {
  background-color: #E5E5E5 !important;
  color: #727272 !important;
  border: 1px solid #E5E5E5 !important;
}
.MuiPickersDay-root:not(.Mui-selected):focus {
  background-color: transparent !important;
}
.MuiTablePagination-actions {
  gap: 5px;
  display: inline-flex;
}
.MuiDataGrid-cell td{
  border-bottom: none !important;
}
.MuiDataGrid-virtualScroller{
  min-height: 50px;
}
.order-status {
  padding: 6px 9px;
  border-radius: 5px !important ;

  font-size: 11px;
  font-weight: 400;
  // max-width: 90px;
  display: flex;
  align-items: center;
  color: #15640f;
    background: #d5ffd4;
    border: 1px solid #d5ffd4;
  svg {
    height: 15px !important;
    width: 15px !important;
    margin-left: 5px;
  }
}
.MuiStepLabel-iconContainer {
  width: 25px;
  height: 25px;
  justify-content: center;
}
